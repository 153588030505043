<template>
  <GlobalDialog class="flex flex-col gap-4 w-[450px] min-h-[400px] dialog avv-gray-100 avv-bg">
    <h2 class="font-bold" v-text="localize('title')" />

    <div class="flex-grow flex flex-col">
      <!-- Loading state -->
      <div v-if="dialogData.ai.loading" class="flex-grow flex flex-col items-center justify-center">
        <AIComments :logs="dialogData.ai.logs" :loading="dialogData.ai.loading" :error="dialogData.ai.error" />
        <p class="mt-2 text-gray-600">{{ localize('generating_message') }}</p>
      </div>
      <!-- Ready state with static text -->
      <div v-else-if="dialogData.ai.entriesCreated"
        class="flex-grow flex flex-col items-center justify-center text-center">
        <p class="text-gray-800">{{ localize('document_ready') }}</p>
        <p class="text-gray-600">{{ localize('click_next') }}</p>
      </div>
      <!-- Initial state -->
      <div v-else class="flex-grow">
        <Textarea :height200px="dialogData.ai.logs.length < 1" :placeholder="localize('textarea_placeholder')"
          @input="textInput" />
        <AIComments :logs="dialogData.ai.logs" :loading="dialogData.ai.loading" :error="dialogData.ai.error" />
      </div>
    </div>

    <!-- Toggle switch -->
    <div class="flex items-center">
      <AToggle :active="dialogData.ai.enforceAnswers" :title="localize('toggle')" @click="toggleEnforceAnswers" />
    </div>

    <!-- Buttons -->
    <div class="flex justify-between mt-auto">
      <div>
        <PreStyleButton mode="primary_full" @click="close">
          {{ localize('cancel') }}
        </PreStyleButton>
      </div>
      <div class="flex gap-x-2.5">
        <PreStyleButton v-if="!dialogData.ai.entriesCreated" mode="primary_full"
          :disable="buttonGenerateEntriesDisable || dialogData.ai.loading" @click="sendDocToAI">
          {{ localize('next') }}
        </PreStyleButton>
        <PreStyleButton v-else mode="primary_full" :disable="dialogData.ai.loading || dialogData.ai.redirecting"
          @click="createDoc">
          <template v-if="dialogData.ai.redirecting">
            <span class="flex items-center gap-2">
              <span class="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></span>
              {{ localize('redirecting') }}
            </span>
          </template>
          <template v-else>
            {{ localize('next') }}
          </template>
        </PreStyleButton>
      </div>
    </div>
  </GlobalDialog>
</template>
<script lang="ts" setup>
import { defineComponent, ref, computed } from 'vue'
import AiApi from '@api/AiApi'
import GlobalDialog from '../dialogs/GlobalDialog.vue'
import PreStyleButton from '../_abstract/PreStyleButton.vue'
import AIComments from './components/AIComments.vue'
import Textarea from './components/Textarea.vue'
import AToggle from '../_abstract/AToggle.vue'
import { createLog, createAndSubmitForm } from './utils'
import type { AIDialogFromTextData } from './utils'

const props = defineProps<{
  templates?: number[]
}>()

const emit = defineEmits<{
  (e: 'callback'): void
}>()

const dialogData = ref<AIDialogFromTextData>({
  text: {
    value: '',
    notTouched: true
  },
  ai: {
    logs: [],
    loading: false,
    firstTime: true,
    entriesCreated: false,
    error: false,
    entries: {},
    enforceAnswers: false,
    redirecting: false
  }
})

//Buttons conditions
const buttonGenerateEntriesDisable = computed(() => {
  return dialogData.value.text.notTouched
})

//Actions
const close = () => {
  emit('callback')
}

const toggleEnforceAnswers = () => {
  dialogData.value.ai.enforceAnswers = !dialogData.value.ai.enforceAnswers
}

const textInput = (e: Event) => {
  const eTarget = e.target as HTMLInputElement
  if (eTarget.value !== '') dialogData.value.text.notTouched = false
  else dialogData.value.text.notTouched = true
  dialogData.value.ai.entriesCreated = false
  dialogData.value.ai.error = false
  dialogData.value.text.value = eTarget.value
}

const sendDocToAI = () => {
  dialogData.value.ai.loading = true
  if (dialogData.value.ai.firstTime) dialogData.value.ai.firstTime = false

  const data = {
    text: dialogData.value.text.value,
    template_id: props.templates![0],
    enforce_answers: dialogData.value.ai.enforceAnswers
  }

  AiApi.docxToEntries({
    data: data,
    headers: { 'Content-Type': 'application/json' }
  })
    .then((response) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.entriesCreated = true
      dialogData.value.ai.entries = response.entries
      createLog(response.evidence)

      dialogData.value.ai.logs.push({
        fileName: 'text',
        body: createLog(response.evidence)
      })
    })
    .catch((e) => {
      dialogData.value.ai.loading = false
      dialogData.value.ai.error = true
    })
}


const createDoc = () => {
  dialogData.value.ai.redirecting = true
  const data = [
    {
      name: 'ordered_template_ids[]',
      value: props.templates![0].toString()
    },
    {
      name: 'entries',
      value: JSON.stringify(dialogData.value.ai.entries)
    }
  ]
  createAndSubmitForm('../documents/new', data)
}

const localize = (key: string) => window.localizeText(`documents.ai_create.from_text.dialog.${key}`)
</script>
<script lang="ts">
export default defineComponent({ name: 'AICreateFromTextDialog' })
</script>
